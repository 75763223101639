import { graphql } from "gatsby";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import PageTemplate from "gatsby-theme-hypercore/src/templates/page";
import theme from "../../theme";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      // NOTE: idiosyncratic layout rules (simplify?)
      ".header": {
        padding: theme.spacing(6, 0),
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(15, 0),
        },
        "& .MuiGrid-container": {
          maxWidth: 450,
          margin: "auto",
          [theme.breakpoints.up("md")]: {
            maxWidth: 1020,
            margin: "unset",
          },
        },
        "& .two-col-block__left, & .two-col-block__right": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        "& .two-col-block__right": {
          maxWidth: "fit-content",
          marginLeft: "auto",
        },
        "& .two-col-block__right > p": {
          maxWidth: 570,
        },
      },
    },
  })
);

export default function ResearchIndex(props) {
  useStyles();
  return <PageTemplate {...props} />;
}

export const pageQuery = graphql`
  query ResearchIndexQuery($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        name
        embeddedImages {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
